<template>
  <div>
    <div class="user-title">
      <h2>{{title}}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonUserCenterTitle',
  props:{
    title:{
      type:String,
      default(){
        return ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.user-title{
  height: 27px;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 5px;
  margin-bottom: 10px;
  h2{
    float: left;
    font-size: 16px;
    font-weight: 700;
    color: #555;
    height: 27px;
    line-height: 27px;
    margin-right: 20px;
  }
   h2:before {
    content: "";
    float: left;
    height: 16px;
    width: 3px;
    background: #F32525;
    margin: 6px 10px 0 0;
  }
}
</style>
