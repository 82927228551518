<template>
  <div>
    <el-card>
      <common-user-center-title :title="'我的优惠券'"></common-user-center-title>
      <div class="coupon-list">
        <div class="coupon-item" v-for="item in myCouponList" :key="item.id">
          <div class="coupon-bg">
            <div class="coupon-white"></div>
            <div class="coupon-circular"></div>
          </div>
          <div class="coupon-info">
            <div class="coupon-value">￥<span>{{item.couponMoney}}</span></div>
            <div class="coupon-full-money">
              <div class="full-money">满{{item.fullMoney}}可用</div>
              <div class="coupon-name">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页区域 -->
      <el-pagination
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />

    </el-card>
  </div>
</template>

<script>
import { getMyCouponListUrl } from '@/api/index'
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
export default {
  name: 'MyCoupon',
  components: {
    commonUserCenterTitle
  },
  data(){
    return{
      queryInfo: {
        pageSize: 12,
        page: 1,
        field: '',
        desc: '',
      },
      province:[{
        city:[{
          district:[{}]
        }]
      }],
      myCouponList: [],
      total: 0,
    }
  },
  created () {
    this.initData()
  },
  methods:{
    initData(){
      this.getMyCouponList()
    },
    // 获取我的优惠券列表
    async getMyCouponList(){
      const{data:res} = await this.$http.get(getMyCouponListUrl,{params:this.queryInfo})
      console.log(res)
      if(res.code !== 200) return this.$message.error(res.msg)
      this.myCouponList = res.data.list
      this.total = res.data.total
    },
    // 分页页码显示条数
    handleSizeChange (newSzie) {
      // 把每页显示多少数据重新赋值
      this.queryInfo.pageSize = newSzie
      // 重新获取数据并显示
      this.getMyCouponList()
    },
    // 页面切换事件
    handleCurrentChange (newPage) {
      this.queryInfo.page = newPage
      // 重新获取数据并显示
      this.getMyCouponList()
    },
    // 选择优惠券事件
    getMyCouponListFun(){
      let couponCode = 'CTMSAIGP_EJPSFJ_WGOHJPO'
      console.log(couponCode)
    },
  }
}
</script>

<style lang="less" scoped>
  .coupon-list{
    display: flex;
    justify-content: start;
    flex-flow: wrap row;
    .coupon-item{
      background-color: #F0D1A2;
      border-radius: 10px;
      height: 70px;
      padding: 10px;
      position: relative;
      width: 258px;
      margin-right: 20px;
      margin-bottom: 20px;
      &:nth-child(3n){
        margin-right: 0;
      }
      .coupon-bg{
        .coupon-white{
          background-color: #ffffff;
          border-radius: 10px;
          position: absolute;
          height: 70px;
          width: 126px;
          top: 10px;
          left: 15px;
          z-index: 1;
        }
        .coupon-circular{
          background-color: #F0D1A2;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          position: absolute;
          left: 0px;
          top: 50%;
          margin-top: -15px;
          z-index: 2;
        }
      }
      .coupon-info{
        position: relative;
        z-index: 3;
        display: flex;
        .coupon-value{
          font-size: 14px;
          color: #f61f1b;
          font-weight: bold;
          line-height: 70px;
          margin-left: 25px;
          width: 80px;
          span{
            font-size: 22px;
          }
        }
        .coupon-full-money{
          margin-left: 40px;
          color: #462301;
          .full-money{
            font-size: 16px;
            line-height: 30px;
            margin-top: 10px;
            font-weight: bold;
          }
          .coupon-name{
            font-size: 12px;
            line-height: 20px;
          }
        }
      }

    }
  }
</style>
